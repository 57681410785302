<template>
  <div class="app-container">
    <Title :data="data"></Title>
    <div style="display: flex">
      <batchadd
        title="教师总结"
        text="教师总结"
        :dataList="form.teacher_summary"
        @edit="clickUpload"
        style="margin-bottom: 20px"
      ></batchadd>
      <Uptips style="width: 20%"></Uptips>
    </div>
    <div class="bottom_btn">
      <el-button @click="$router.go(-1)">返回</el-button>
      <el-button type="primary" @click="submit" :loading="btnLoading"
        >保存并提交</el-button
      >
    </div>
  </div>
</template>

<script>
// import BatchAdd from "@/components/Batch/index.vue";
import batchadd from "@/components/Batch_add/index.vue";
import request from "@/utils/request";
export default {
  components: {
    batchadd,
  },
  data() {
    return {
      selected: false,
      btnLoading: false,
      list: [],
      form: {},
      listQuery: {
        type: 5,
        class_course_id: "",
      },
    };
  },
  created() {
    this.listQuery.type = this.$route.query.type;
    this.listQuery.class_course_id = this.$route.query.class_course_id;
    this.data = JSON.parse(this.$route.query.data);
    localStorage.setItem("classdata", JSON.stringify(this.data));
    console.log(this.data, "===========");
    this.form = {
      teacher_summary: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "teacher_summary",
        data: [],
        is_store: "",
      },
    };
    this.getDetail();
  },
  methods: {
    clickUpload(e) {
      this.form.teacher_summary.is_store = 1;
      this.$forceUpdate(); //强制更新
    },
    getDetail() {
      request({
        url: "/api/teacherend/eduAdmin/dataDetail",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        if (response.data.teacher_summary) {
          this.form = response.data;
        }
      });
    },
    submit() {
      if (this.form.teacher_summary.is_store != 1) {
        this.$alert("请修改或者上传后提交", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            return;
          },
        });
        return;
      }
      let isup = this.form.teacher_summary.data.findIndex(
        (value) => value.name == null
      );
      if (isup != -1) {
        this.$message({
          type: "warning",
          message: "添加后,请上传文件!!",
        });
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/teacherend/eduAdmin/dataStore",
        method: "post",
        data: this.form,
      })
        .then((response) => {
          this.btnLoading = false;
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.getDetail();
          // this.$router.go(0);
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.content {
  width: 50%;
  margin: 0 auto;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }
}
.bottom_btn {
  display: flex;
  justify-content: center;
}
</style>
